@import "src/styles/variables";

html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background-color !important;
}

.react-flow__node.annotation {
  width: 320px;

  > div {
    padding: 20px;
  }
}
.react-flow__node.annotation .react-flow__handle {
  display: none !important;
}

.react-flow__edges.react-flow__container {
  z-index: 2 !important;
}

.draggable {
  top: auto !important;
  left: auto !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  min-height: 100vh;
  // eslint-disable-next-line
  min-height: fill-available;
  // eslint-disable-next-line
  min-height: -webkit-fill-available;
}

.ProseMirror p.is-editor-empty:first-child::before {
  color: $slidesTextColorSecondary;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

/* Basic editor styles */
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}

.sc-vm-file {
  border-radius: 16px !important;
}
